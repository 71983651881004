import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { TRAINER_EVENTS } from 'Consts/routes';

import { getFullName } from 'Utils/user';
import { parseToQueryString } from 'Utils/querystring';

import StyledComponent from 'Components/core/StyledComponent';

export default class TrainerEventMeet extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };

    state = {
        meetUrl: null,
        fullscreen: false,
    }

    componentDidMount = () => {
        const meetUrl = this.getMeetUrl();

        this.setState({
            meetUrl,
        });
    }

    getMeetUrl = () => {
        const { profile } = this.props;

        let displayName = 'Trener';

        if(profile.name && profile.surname) {
            displayName = getFullName(profile, 'nameSurname').label;
        }
        
        return parseToQueryString(profile.onlineMeetHostUrl, {
            displayName: displayName,
            floatSelf: '',
        });
    }

    openFullscreen = () => {
        let elem = document.getElementById("meet-container");

        if (elem.requestFullscreen) {
          elem.requestFullscreen({ navigationUI: "hide" });
        } else if (elem.webkitRequestFullscreen) { /* Safari */
          elem.webkitRequestFullscreen({ navigationUI: "hide" });
        } else if (elem.msRequestFullscreen) { /* IE11 */
          elem.msRequestFullscreen({ navigationUI: "hide" });
        }

        this.setState(prevState => ({
            fullscreen: true,
        }))
    }

    closeFullscreen = () => {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        }

        this.setState(() => ({
            fullscreen: false,
        }))
    }

    reloadMeet = () => {
        let iframe = document.getElementById('iframe');
        iframe.src = iframe.src;
    }

    render() {
        const { history } = this.props;
        const { meetUrl, fullscreen } = this.state;

        return (
            <StyledComponent
                className={classnames(
                    'trainer-event-meet',
                    `fullscreen-${fullscreen}`
                )}
                styles={require('./styles')}
            >
                <div className="meet-container" id="meet-container">
                    <div className="buttons-container">
                        <div className="button-wrapper">
                            <a 
                                className="toggle"
                                onClick={() => fullscreen ? this.closeFullscreen() : this.openFullscreen()}
                                id="toggle"
                            >
                                {fullscreen ? (
                                    <FontAwesomeIcon icon={['fa', 'compress']} />
                                ) : (
                                    <FontAwesomeIcon icon={['fa', 'expand']} />
                                )}
                            </a>
                        </div>
                        <div className="button-wrapper">
                            <a
                                className="back-to-events-list"
                                onClick={() => history.push(TRAINER_EVENTS.path)}
                                id="back"
                            >
                                <FontAwesomeIcon icon={['fa', 'arrow-left']} />
                            </a> 
                        </div>
                        <div className="button-wrapper">
                            <a
                                className="reload"
                                onClick={() => this.reloadMeet()}
                                id="reload"
                            >
                                <FontAwesomeIcon icon={['fa', 'sync']} />
                            </a> 
                        </div>
                    </div>
                    <iframe
                        id="iframe"
                        src={meetUrl}
                        allow="camera; microphone; fullscreen; speaker; display-capture"
                    />
                </div>
            </StyledComponent>
        );
    }
}